<template>
<div class="tabulation"  v-loading="loading" element-loading-text="加载中" element-loading-background="rgb(255, 255, 255, 0.5)">
  <el-card class="chart-card">
    <div style="margin-top: 20px;">
      <div  v-for="(item,index) in totality" :key="index">
        <!-- 单选 -->
        <div class="choice" v-show="item.type==1">
          <!-- 题目 -->
          <div class="subjects"><span>*</span> {{item.name }} <span class="mold"> [单选]</span></div>
          <!-- 表格统计 -->
          <el-table
          :data="item.option"
          style="width: 50%"
          row-key="unionId"
          @cell-mouse-enter="obtain"
          border
          :empty-text="texts"
          :header-cell-style="{ background: '#F5F7FA' }"
          @row-click="unit"
          >
             <el-table-column align="center" label="选项内容" prop="name" :formatter="formatSex"/>
             <el-table-column align="center" label="总计" prop="number" :formatter="formatSex"/>
          </el-table>
        </div>

        <!-- 多选 -->
        <div class="choice" v-show="item.type==2">
          <!-- 题目 -->
          <div class="subjects"><span>*</span> {{item.name }} <span class="mold"> [多选]</span></div>
          <!-- 表格统计 -->
          <el-table
          :data="item.option"
          style="width: 50%"
          row-key="unionId"
          @cell-mouse-enter="obtain"
          border
          :empty-text="texts"
          :header-cell-style="{ background: '#F5F7FA' }"
          @row-click="unit"
          >
             <el-table-column align="center" label="选项内容" :show-overflow-tooltip='true' prop="name" :formatter="formatSex"/>
             <el-table-column align="center" label="总计" :show-overflow-tooltip='true' prop="number" :formatter="formatSex"/>
          </el-table>
        </div>

        <!-- 简答题 -->
        <div class="choice" v-show="item.type==3">
          <!-- 题目 -->
          <div class="subjects"><span>*</span> {{item.name }} <span class="mold"> [简答题]</span></div>
          <div v-for="(ite,index) in item.option" :key="index">
            <div class="examine" @click="check(ite.code)">
              <el-icon class="cancel"> <Search /> </el-icon>查看详情
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-card>
</div>
</template>
<script>
import { Search} from "@element-plus/icons-vue";
import '@wangeditor/editor/dist/css/style.css' // 引入 css
import { onBeforeUnmount, ref, onMounted } from 'vue'
import { DTdata } from '../../utils/api'
import qs from 'qs'
import { useRouter  } from "vue-router";
export default{
  name:"consequence",
    components: {
    Search,
  },
  setup(){
    // 加载中
    let loading=ref(true)
    const router = useRouter()
    // 查看详情获取code
    let check=(code)=>{
      sessionStorage.setItem('bamboo', code)
      router.push('/question')
      console.log(code)
    }
    // 总体数据
    let totality=ref([])
    // 答题问卷总数居
    let list =()=>{
      let data={
        id:sessionStorage.getItem('summation')
      }
      DTdata(qs.stringify(data)).then((res)=>{
        console.log(res)
        if(res.data.code==0){
          loading.value=false
          totality.value=res.data.data.quesAnswerInfo
        }
      })
    }
    // 按钮加载
    const ConfirmLoading=ref(false)

    // 模拟 ajax 异步获取内容
    onMounted(() => {
      list()
    })
 
 
    // 组件销毁时，也及时销毁编辑器
    onBeforeUnmount(() => {
    })
 
    return{
      // 加载中
      loading,
      // 查看详情
      check,
      // 总体数据
      totality,
      // 按钮加载
      ConfirmLoading,
    }
  }
 }
</script>
<style lang='scss' scoped>
.cancel {
  margin-right: 5px;
  font-weight: 400;
}
.examine{
  margin-left: 1%;
  color: #409EFF;
  font-size: 14px;
  cursor:pointer;
  // text-decoration:underline;
}
::v-deep .el-textarea.is-disabled .el-textarea__inner{
  color: #000;
}
::v-deep .el-textarea__inner{
  height: 150px;
}
.content{
  width: 40%;
  height: 50%;
  margin-left: 1%;
}
::v-deep .el-checkbox:last-of-type{
  margin-left: 1%;
}
::v-deep .el-radio:last-child{
  margin-left: 1%;
}
.choice{
  margin-bottom: 20px;
}
.mold{
  font-size: 13px;
  color: #A7A7A7 !important;
}
::v-deep .subjects{
  margin-left: 3px;
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: bold;
  color: var(--el-text-color-regular);
  span{
    color: var(--el-color-danger);
  }
}
::v-deep .chart-card::-webkit-scrollbar{
  display:none
}
.chart-card{
  height: calc(100vh - 145px);
  overflow: auto;
}
.subject{
  margin-left: 3px;
  font-size: var(--el-form-label-font-size);
  font-weight: bold;
  margin-bottom: 5px;
  color: var(--el-text-color-regular);
  span{
    color: var(--el-color-danger);
  }
}
.el-button{
  width: 100px;
  height: 40px;
  font-size: 16px;
}
.el-form-item__content{
  align-items: flex-end;
}
.referrals{
  color: #cccccc;
  margin-left: 5px;
}
.styB .el-upload--picture-card{
  width:110px;
  height:110px;
  line-height:110px;
}
.styC .el-upload--picture-card{
  display:none;   
}
.el-form-item{
  align-items: center;
}
.aaaa{
  width: 50%;
}
::v-deep .el-card__body {
  padding: 0;
}
::v-deep .el-main{
  overflow: auto !important;
}
.el-card.is-always-shadow{
  width: 95%;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  margin-top: 10px;
}
.tabulation {
  // margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  overflow: auto !important;
}
</style>
<style scoped>
::v-deep .upload-demo{
  display: flex;
}
::v-deep .el-input{
  height: 44px !important;
}
::v-deep .el-form-item__label{
  width: 80px !important;
  font-weight: bold;
}
</style>
